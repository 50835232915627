.LegendItem {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

.LegendItem_color {
    width: 21px;
    height: 21px;
    border: 1px solid #dedede;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.LegendItem_name {
    display: inline-block;
    vertical-align: middle;
}