html, body, #app {
    height: 100%;
}

body {
    font-size: 18px;
    color: #666;
    position: relative;
}

main.main-content {
    min-height: calc(100% - 75px);
}

.react-date-picker__wrapper {
    border: none !important;
}

#accomodation {
    padding: 50px 0;
}

#accomodation .banner-img {
    width: 100%;
    border-radius: 15px;
    margin-bottom: 30px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .15);
}

#accomodation h2 {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 45px;
    color: #313131;
}

#accomodation p {
    font-size: 18px;
}

#accomodation ul {
    padding-left: 15px;
}

@media (max-width: 576px) {
    #accomodation {
        padding: 20px 0;
    }

    #accomodation h2 {
        font-size: 32px;
        margin-bottom: 25px;
    }

    #accomodation .banner-img {
        margin-bottom: 20px;
    }
}

.primary-btn {
    display: inline-block;
    padding: 10px 25px;
    background: #262626;
    color: #fff;
    border-radius: 15px;
    text-decoration: none;
    margin-top: 15px;
}

.primary-btn:hover, .primary-btn:focus {
    background: #161616;
    color: #fff;
    text-decoration: none;
}

span.require {
    color: #f00;
}

input, select, textarea {
    border-radius: 0 !important;
}

.NotFound {
    margin: 30px 0;
}

.Loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: loader-spin 1.5s linear infinite;
    margin: 0 auto;
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.fc-theme-standard a {
    color: #333;
    text-decoration: none;
}

.react-datetime-picker__wrapper {
    border: none;
}

/*
.res-day {
    position: relative;
}

.res-day::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 74px 130px 0 0;
    border-color: #ffffff #ef6767 transparent transparent;
}

.res-day::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 74px 130px;
    border-color: transparent transparent #fff #ff2a2a;
}
*/
